import bankTranfertImg from "assets/images/wallet/bankTransfer.svg";
import bankTransferColortImg from "assets/images/wallet/bankTransferColor.svg";
import folderImg from "assets/images/wallet/folderImg.png";
import masterCardImg from "assets/images/wallet/masterCard.svg";
import masterCardGrayImg from "assets/images/wallet/masterCardGray.svg";
import AWSpay from 'components/AWSpay';
import TextFieldWithSelect from "components/Form/TextFieldWithSelect/TextFieldWithSelect";
import { useSBSState } from 'context/global';

import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";

export default function RechargeModal({
	modalRecharge,
	toggleModalRecharge,
	modalSendRequest,
	toggleModalSendRequest,
	data,
	bankTranfer,
	bankAmount,
	setBankAmount,
	image,
	setImage,
	currency,
	creditAmount,
	setCreditAmount,
	AddDebit,
	formData,
	actionType
}) {
	const { payment } = Locale;
	const { companyInfo  } = useSBSState();
	const ONLINE_CURRENCY = process.env.REACT_APP_ONLINE_CURRENCY

	const [radio, setRadio] = useState(currency===ONLINE_CURRENCY ? "credit": "transfer");
	const handleUpload = (e) => {
		const files = e.target.files;
		setImage(files[0]);
	};

	const [Validation, setValidation] = useState({
		bankAmount: undefined,
		img: undefined,
		credit: undefined,
	});
	useEffect(() => {
		const validateField = () => {
			setValidation({
				bankAmount: !isNaN(bankAmount) ? true : false,
				img: image?.name.length > 0 ? true : false,
				credit: !isNaN(creditAmount) ? true : false,
			});
		};
		validateField();
	}, [Validation.bankAmount, Validation.img, Validation.creditAmount]);


	return (
		<div>
			<Modal
				size="md"
				isOpen={modalRecharge}
				toggle={toggleModalRecharge}
			>
				<ModalHeader
					className="align-items-center py-1 border-none"
					toggle={toggleModalRecharge}
				>
					{payment.messages.rechargeWallet}
				</ModalHeader>
				<ModalBody>
				{actionType=="form-redirect"?
    <AWSpay data={formData} /> :	
			<div className="container m-auto">
							<div className="recharge-radio d-flex justify-content-center my-4">
							{currency==ONLINE_CURRENCY?	<div
								 	
									className={`radio-control box-radio pointer ${
										radio === "credit" ? "border-active" : ""
									}`}
									onClick={()=>setRadio("credit")}
								>
									<input
										className="mx-1"
										type="radio"
										name="RechargeRadios"
										id="radios1"
										value="credit"
										checked={radio === "credit"}
										onChange={(e) => {
											setRadio(e.target.value);
										}}
									/>
									<img src={radio === "credit"?masterCardImg:masterCardGrayImg} alt="master card"/>
									<p>{payment.messages.payCredit}</p>
								</div> :""}


								{companyInfo?.country?.nat_code === "IDN" && currency=="IDR" ? 
								null : <div
								 className={`radio-control box-radio pointer ${
									 radio === "transfer" ? "border-active" : ""
								 }`}
								 onClick={()=>setRadio("transfer")}
							 >
								 <input
									 className="mx-1"
									 type="radio"
									 name="RechargeRadios"
									 id="radios2"
									 value="transfer"
									 checked={radio === "transfer"}
									 onChange={(e) => {
										 setRadio(e.target.value);
									 }}
								 />
								 
								 <img src={radio === "transfer" ?bankTransferColortImg:bankTranfertImg} alt="master card"  height={40} width={40}/>
								 <p>{payment.messages.bankTranfer}</p>
							 </div>}
							</div>
							{radio === "credit" ? (
								// <TextField
								// 	label={payment.messages.amount}
								// 	placeholder={"0.00"}
								// 	value={creditAmount}
								// 	onChange={(e) => {
								// 		setCreditAmount(e.target.value);
								// 		setValidation({ ...Validation, credit: creditAmount });
								// 	}}
								// />
								<div className="text-field-option">
									<TextFieldWithSelect
										selectPosition="append"
										placeholder={"0.00"}
										value={creditAmount}
										onChange={(e) => {
											setCreditAmount(e.target.value);
											setValidation({ ...Validation, credit: creditAmount });
										}}
										disabled={true}
									
									>
										<option value="en" >{data?.currency}</option>
										
										{/* <option value="ar">EGP</option> */}
									</TextFieldWithSelect>
								</div>
							) : null}
							{radio === "transfer" ? (
								<div className=" text-field-option">
									{/* className="form-group full-input w-100" */}
									<label className="mb-0">{payment.messages.amount}</label>
									<TextFieldWithSelect
										selectPosition="append"
										label={payment.messages.amount}
										placeholder={"0.00"}
										value={bankAmount}
										onChange={(e) => {
											setBankAmount(e.target.value);
											//validateField();
											setValidation({ ...Validation, bankAmount: bankAmount });
										}}
										color={Validation.bankAmount ? "" : "danger"}
										disabled={true}
									>
										<option value="en" >{data?.currency}</option>
										
									</TextFieldWithSelect>

									{/* <TextField
										label={payment.messages.amount}
										placeholder={"0.00"}
										value={bankAmount}
										onChange={(e) => {
											setBankAmount(e.target.value);
											//validateField();
											setValidation({ ...Validation, bankAmount: bankAmount });
										}}
										color={Validation.bankAmount ? "" : "danger"}
									/> */}
									<div
										className={`bg-white p-2 text-center  position-relative upload-images w-100 mt-3  
									${image != undefined ? "justify-content-between" : "justify-content-center"}
									`}
									>
										{/* <p className="text-left">Drag your document or photo.</p> */}
										<div className="d-flex align-items-center">
											<small className="d-flex text-gray ">
												<img
													src={folderImg}
													alt="folder img"
													className={`${image != undefined ? "visible" : ""}`}
												/>
												<p
													className={`text-left mx-2 upload-message ${
														image != undefined ? "hiden" : ""
													}`}
												>
													{payment.messages.dragPhote}
												</p>{" "}
												<p className="`text-left mx-2">{image && image.name}</p>{" "}
												{image != undefined ? (
													<span className="btn-remove-image ">
														<i
															class="fas fa-times-circle text-danger"
															onClick={() => setImage(null)}
														></i>
													</span>
												) : (
													""
												)}
											</small>
											<button
												className={`btn btn-yellow chose ${
													image != undefined ? "hiden" : ""
												}`}
											>
												{payment.messages.browseFile}
											</button>
										</div>
										{image == undefined ? (
											<input
												onChange={(e) => {
													handleUpload(e);
													//validateField();
													setValidation({ ...Validation, img: image });
												}}
												type="file"
												id="uploadFile"
												accept="image/*"
												color={Validation.img ? "danger" : ""}
											/>
										) : null}
									</div>
								</div>
							) : null}
							<button
								disabled={
									!(
										(Validation.img && Validation.bankAmount) ||
										Validation.credit
									)
								}
								className="btn btn-yellow my-3 py-2 w-100 modal-btn text-uppercase"
								onClick={() => {
									//toggleModalRecharge(null);
									//toggleModalSendRequest(modalSendRequest);

									if (radio === "transfer") {
										bankTranfer();
									} else {
										AddDebit();
									}
								}}
							>
								{payment.messages.rechargeWallet}
							</button>
					</div>}
				</ModalBody>
			</Modal>
		</div>
	);
}
