import React, { useEffect, useState } from "react";
import image1 from "assets/images/webBuilder/template/1.png";
import image2 from "assets/images/webBuilder/template/2.png";
import image3 from "assets/images/webBuilder/template/3.jpg";
import image4 from "assets/images/webBuilder/template/4.png";
import image5 from "assets/images/webBuilder/template/5.png";
import image7 from "assets/images/webBuilder/template/7.png";
import image10 from "assets/images/webBuilder/template/10.jpg";
import { ReactComponent as EditIcon } from "assets/images/umrah-operations/edit-icon.svg";
// import { ReactComponent as ViewIcon } from "assets/images/Tds/eye.svg";
import { useHistory } from "react-router-dom";
import template1 from "./Templates/template1.json";
import template2 from "./Templates/template2.json";
import template3 from "./Templates/template3.json";
import template4 from "./Templates/template4.json";
import template5 from "./Templates/template5.json";
import template7 from "./Templates/template7.json";
import template10 from "./Templates/template10.json";
// import templateOld from "./page.json";
import { setPageBuilder } from "services/webBuilder";
import { getPageBuilder, getPageBuilderByCompany } from "services/webBuilder";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ShowSubscription from 'helpers/ShowSubscription';

import SelectLanguagesModal from './shared/ChooseLanguagesModal';
import { formatPageData } from './helpers/formatPageData';


export const languagesLookup = [
	{ name: "English", code: "en", direction: "ltr" },
	{ name: "Arabic", code: "ar", direction: "rtl" },
	{ name: "Indonesian", code: "id", direction: "ltr" },
	// { name: "Hindi", code: "IN", direction: "ltr" },
	// { name: "French", code: "fr", direction: "ltr" },
	// { name: "Uzbekistan", code: "uz", direction: "ltr" },
	// { name: "Italian", code: "it" },
	{ name: "Russian", code: "ru",direction: "ltr"  },
]


export default function ChooseTemplate() {
	// hooks
	const history = useHistory();
	const { name, bundle } = useParams();


	const themes = [
		// {
		//   id: "old", name: "Customize your website", src: imageCustomize, initialData: templateOld, url: `/${bundle}/web-builder-v1/edit`
		// },
		{ id: "theme-1", name: "Theme - Style 1", src: image1, initialData: template1, url: `/${bundle}/web-builder-v2/edit` },
		{ id: "theme-2", name: "Theme - Style 2", src: image2, initialData: template2, url: `/${bundle}/web-builder-v2/edit` },
		{ id: "theme-3", name: "Theme - Style 3", src: image3, initialData: template3, url: `/${bundle}/web-builder-v2/edit` },
		{ id: "theme-4", name: "Theme - Style 4", src: image4, initialData: template4, url: `/${bundle}/web-builder-v2/edit` },
		{ id: "theme-5", name: "Theme - Style 5", src: image5, initialData: template5, url: `/${bundle}/web-builder-v2/edit` },
		{ id: "theme-7", name: "Theme - Style 7", src: image7, initialData: template7, url: `/${bundle}/web-builder-v2/edit` },
		{ id: "theme-10", name: "Theme - Style 10", src: image10, initialData: template10, url: `/${bundle}/web-builder-v2/edit` },
	];

	// states
	const [languagesModal, setLanguagesModal] = useState({
		isOpen: false,
		languagesList: [],
		defaultLanguage: null
	});
	const [selectedTheme, setSelectedTheme] = useState(null);

	function toggleLanguagesModal(selectedLanguages) {
		setLanguagesModal({
			isOpen: !languagesModal.isOpen,
			languagesList: selectedLanguages || []
		})
	}

	async function selectTemplateConfig() {
		const languagesList = languagesModal.languagesList;
		// const languagesCodes = languagesList.map(l => l?.code);
		const res = await setPageBuilder({

			page_builder_config: formatPageData({
				contentData: selectedTheme?.initialData,
				languagesList,
				defaultLanguage: languagesModal.defaultLanguage,
				themeConfig: selectedTheme?.initialData
			})
		});
		
		if (res?.status === 200) {
			history.push(selectedTheme.url);
			toggleLanguagesModal(null);
		}
	}

	const getPageDetails = async () => {
		let res;
		if (!name) {
			res = await getPageBuilder();
		} else {
			res = await getPageBuilderByCompany(name);
		}

		if (res.status === 200 && res?.data?.page_builder_config) {
			if (res?.data?.page_builder_config?.style?.theme === "theme-old") {
				history.push(`/${bundle}/web-builder-v1/edit`);
			} else {
				history.push(`/${bundle}/web-builder-v2/edit`);
			}
		}
	};

	useEffect(() => {
		getPageDetails();

		return () => { };
	}, []);

	return (
		<ShowSubscription module={"website_builder"}>
			<div className="select-template">
				<h3 className="selectTemplate-heading">
					One simple click and your website is ready to go!
				</h3>
				<p className="selectTemplate-text">Pick out one of these templates</p>
				<div className="container">
					<div className="grid-template">
						{themes?.map((template, idx) => (
							<div className="m-t-2 template-container" key={`${template?.id}-${idx}`}>
								<div
									style={{
										backgroundImage: `url(${template.src})`,
									}}
									className={`selectTemplate-overlay`}
								>
									<div className="selectTemplateOverlay-button">
										<button
											className="btn overlay-button"
											onClick={() => {
												toggleLanguagesModal(null)
												setSelectedTheme(template)
											}}
										>
											<EditIcon />
											Select Theme
										</button>
										{/* <button className="btn overlay-button">
										<ViewIcon />
										View
									</button> */}
									</div>
								</div>
								<div className="template-name">
									{template.name}
								</div>
							</div>
						))}
					</div>
				</div>
				<SelectLanguagesModal
					isOpen={languagesModal.isOpen}
					toggle={toggleLanguagesModal}
					setLanguagesModal={setLanguagesModal}
					languagesList={languagesModal.languagesList}
					defaultLanguage={languagesModal.defaultLanguage}
					selectTemplateConfig={selectTemplateConfig}
					languagesLookup={languagesLookup}
				/>
			</div>
		</ShowSubscription>
	);
};


